@import "variables.scss";
@import "layouts.scss";
@import "global.scss";
@import "font.scss";

.component-action-button {
  .action-button {
    margin-top: 16px;
    padding: 12px 24px;
    background-color: $color-primary;
    color: black;
    border-radius: 16px;
    font-weight: 700;
    font-size: 14px;
  }

  .action-button:hover {
    background-color: white;
    color: black;
  }
}
