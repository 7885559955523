// @import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

@import "variables.scss";

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body,
html,
#root {
  height: 100%;
  box-sizing: border-box;
  padding: 0px !important;
  margin: 0px !important;
  font-family: "Pretendard Variable" !important;
}

.cursor-pointer {
  cursor: pointer;
}
