@import "../../../styles/variables.scss";

$app-preview-height-to-width-ratio: 16.5/9;

.component-app-preview {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  border-radius: 24px;
  border: 4px solid $color-primary;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background-image: url("../../../assets/images/problem_app_preview_bg.webp");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  justify-content: flex-end;
  padding: 16px;
  padding-bottom: 48px;
  height: 640px;

  @media (max-width: 480px) {
    width: calc(100vw - 36px);
    height: calc((100vw - 36px) * $app-preview-height-to-width-ratio);
  }

  @media (max-width: 360px) {
    width: calc(100vw - 36px);
    height: calc((100vw - 36px) * $app-preview-height-to-width-ratio);
  }
  @media (max-width: 320px) {
    width: calc(100vw - 24px);
    height: calc((100vw - 36px) * $app-preview-height-to-width-ratio);
  }

  .dialogue-box-container {
    position: relative;
    border-radius: 20px;
    background: rgba(21, 37, 53, 0.7);
    backdrop-filter: blur(5px);
    border: 3px solid #70d3bf;
    width: 100%;
    max-width: 100%;
    color: white;
    font-size: 1rem;
    z-index: 200;
    margin-bottom: 32px;

    .dialogue-text {
      position: relative;
      top: -24px;
      padding: 20px;
      font-weight: 600;
      font-size: 20px;
    }
    .character-name {
      display: flex;
      position: relative;
      left: 20px;
      top: -1rem;
      z-index: 999;
      padding: 0.3rem 0.6rem;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #6bf3b1;
      text-align: center;
      font-size: 0.8rem;
      color: black;
    }

    .toeic-label {
      position: relative;
      top: -1.4rem;
      right: 12px;
      padding: 4px 8px;
      height: 20px;
      padding-bottom: 0px;
      border-radius: 12px 12px 0px 0px;
      background: rgba(18, 18, 18, 0.8);
      z-index: -1;
      text-align: center;
      align-items: center;

      .label-emphasis {
        font-size: 0.65rem;
        font-weight: 700;
        color: $color-primary;
        margin-left: 8px;
        margin-right: 4px;
      }

      .label {
        font-size: 0.6rem;
        font-weight: 400;
        color: white;
        margin-right: 12px;
      }
    }
  }

  .multiple-choice-buttons {
    margin-top: 16px;

    .button-container {
      display: grid;
      // grid-template-rows: repeat(2, 1fr);
      // grid-template-columns: repeat(2, 1fr);
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      width: 100%;
      border: none;
    }

    .button {
      width: 100%;
      height: 100%;
      line-height: 250%;
      background-color: white;
      border-radius: 6px;
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
    }

    .button.active {
      background-color: $color-primary;
      color: black;
    }

    .button.inactive {
      background-color: white;
      color: black;
    }
  }

  .next-button {
    margin-top: 16px;
    padding: 12px 16px;
    border-radius: 12px;
    color: black;
    font-weight: 700;
    font-size: 0.8rem;
  }

  .next-button.active {
    background-color: $color-primary;
  }

  .next-button.inactive {
    background-color: #f0f0f0;
  }

  .answer-modal {
    position: relative;
    width: calc(100% + 32px);
    bottom: -48px;
    left: -16px;
    background: #121920;
    display: flex;
    z-index: 1000;
    border-radius: 20px;
    padding: 20px;

    .title {
      font-size: 1.5rem;
      font-weight: 700;
      color: white;
      margin-bottom: 16px;
      color: $color-primary;
    }

    .title.incorrect {
      color: #e45353;
    }

    .explanation {
      font-size: 1rem;
      font-weight: 500;
      color: white;
      margin-bottom: 16px;
    }
  }
}
