@import "variables.scss";
@import "layouts.scss";

div {
  white-space: pre-line;
  letter-spacing: -0.04em;
}
h1 {
  font-size: 2em;
  font-weight: 900;
}

h2 {
  font-size: 1.5em;
  font-weight: 800;
}

h3 {
  font-size: 1.17em;
  font-weight: 700;
}

h4 {
  font-size: 1em;
  font-weight: 700;
}

h5 {
  font-size: 0.83em;
  font-weight: 500;
}

h6 {
  font-size: 0.67em;
  font-weight: 500;
}

.page-title {
  color: white;
  line-height: 133%;
  font-size: 34px;
  font-weight: 900;
  margin-bottom: 16px;

  @media (min-width: $breakpoint-mobile) {
  }

  @media (min-width: $breakpoint-tablet) {
  }

  @media (min-width: $breakpoint-desktop) {
  }
}

.page-subtitle {
  color: white;
  line-height: 150%;
  font-size: 16px;
  font-weight: 600;

  @media (min-width: $breakpoint-mobile) {
  }

  @media (min-width: $breakpoint-tablet) {
  }

  @media (min-width: $breakpoint-desktop) {
  }
}

.section-title {
  color: black;
  line-height: 133%;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;

  @media (max-width: $breakpoint-mobile) {
    font-size: 7.5vw;
  }

  @media (min-width: $breakpoint-mobile) {
  }

  @media (min-width: $breakpoint-tablet) {
  }

  @media (min-width: $breakpoint-desktop) {
  }
}

.section-description {
  line-height: 150%;
  color: #44484d;
  font-size: 12px;
  font-weight: 500;

  @media (max-width: $breakpoint-mobile) {
    font-size: 3vw;
  }

  @media (min-width: $breakpoint-mobile) {
  }

  @media (min-width: $breakpoint-tablet) {
  }

  @media (min-width: $breakpoint-desktop) {
  }
}

.section-subtitle {
  line-height: 150%;
  color: rgb(56, 56, 56);
  font-size: 16px;
  font-weight: 600;

  @media (max-width: $breakpoint-mobile) {
    font-size: 4.5vw;
  }

  @media (min-width: $breakpoint-mobile) {
  }

  @media (min-width: $breakpoint-tablet) {
  }

  @media (min-width: $breakpoint-desktop) {
  }
}

.text-footer {
  font-size: 12px;
  color: #5e5e5e;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}

.text-italics {
  font-style: italic;
}
