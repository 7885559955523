.page-privacy,
.page-terms {
  line-height: 250%;
  padding: 48px 16px;

  ul,
  ol {
    margin-left: 20px; /* Adjust as needed */
    padding-left: 20px; /* Adjust as needed */
  }

  table {
    border-collapse: collapse;
    border: 1px solid black;
    font-size: 12px;

    th,
    td {
      border: 1px solid black;
      padding: 2px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.7;
  }

  ol {
    font-size: 16px;
    line-height: 1.7;
  }

  ul {
    font-size: 16px;
    line-height: 1.7;
  }
}

td {
}
