@import "../../styles/variables.scss";
@import "styles/colors.scss";
@import "styles/layouts.scss";

.page-home {
  z-index: 1;
  .section-title,
  .section-subtitle,
  .page-title {
    text-align: center;
  }

  .content-text {
    margin-bottom: 32px;
  }

  .section-headline {
    background-color: #121920;
    background-image: linear-gradient(to top, rgba(18, 25, 32, 1) 0%, rgba(18, 25, 32, 0) 100%), url("../../assets/images/home_headline_bg.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    min-height: 640px;

    @media (max-width: $breakpoint-mobile) {
      height: 95vh;
      min-height: unset;
    }

    .ignore-parent-padding {
      position: relative;
      bottom: -48px;
    }
  }

  .section-problem {
    background-color: #121920;
    color: white;

    .section-title {
      color: white;
    }

    .app-preview-action-button {
      background-color: #20df83;
      padding: 10px 8px;
      font-size: 16px;
      font-weight: bold;
      width: 180px;
      color: black;
      border-radius: 40px;
      justify-content: center;
      text-align: center;
      margin: 16px auto;
      position: relative;
      bottom: -36px;
      font-weight: 500;
    }

    .label-container {
      margin: 8px 0px;
    }

    .label-name {
      color: white;
      width: 80px;
      margin-right: 16px;
      text-align: left;
    }

    .label-value {
      color: white;
    }

    .label-value.label-toeic {
      color: #ffe34d;
      font-weight: 900;
    }
    .star {
      width: 24px;
    }
  }

  .section-faq {
    .faq-container {
      margin-top: 32px;
      width: 90%;
      background-color: #f2f7f5;
      border-radius: 24px;
      border: 1px solid #dfe4e6;
      padding: 16px;
    }

    .faq-item {
      padding: 16px;
      width: 100%;
    }

    .faq-item-hr {
      margin-top: 16px;
      height: 2px;
      color: #dfe4e6;
    }

    .faq-item-title {
      font-weight: bold;
      margin-bottom: 8px;
    }

    .faq-item-content {
      font-size: 15px;
    }
  }

  .section-feature-1 {
    overflow: hidden;

    .toeic-images {
      margin-top: 32px;
      gap: 16px;
    }

    .poster-image {
      margin-top: 32px;
      // position: relative;
      max-width: 100vw;
      width: calc(100% + 64px);
      // box-sizing: content-box;
    }
  }

  .section-feature-2 {
    .app-image {
      width: 85%;
      margin-top: 48px;
    }
  }

  .section-feature-3 {
    .card {
      width: 85%;
    }
    .wave-svg {
      margin-top: 36px;
      position: relative;
      left: -32px;
      right: -32px;
      width: calc(100% + 64px);
    }

    .flip-button {
      margin-top: 32px;
      background-color: $color-primary;
      border-radius: 16px;
      padding: 12px 32px;
      font-weight: 16px;
      font-weight: bold;
    }
  }
  .section-transition {
    background-color: #121920;
    padding: 96px 16px;

    .section-title {
      color: white;
      margin: 0 auto;
      text-decoration: underline;
    }
  }

  .section-action {
    background-color: #16261f;
    justify-content: center;
    text-align: center;
    padding: 64px 32px;

    .section-title {
      color: white;
    }

    .section-label {
      margin-bottom: 16px;
      font-weight: 700;
      color: $color-primary;
    }

    .component-action-button {
      width: 240px;
    }

    .wave-svg {
      margin-bottom: 48px;
      position: relative;
      left: -32px;
      right: -32px;
      width: calc(100% + 64px);
    }
  }
}

// Image Sizes

.action-button {
  background-color: $color-primary;
  padding: 16px 8px;
  font-size: 12px;
}

.image-width-limit {
  max-width: 480px;
}

.take-full-width {
  margin: -12px 0px;
}

.toeic-image {
  max-height: 40px;
}

.poster-image {
  width: 200px;
}

.ignore-parent-padding {
  margin: 0px -16px;
}
