@import "../styles/variables.scss";

.navigation-footer {
  background-color: #121920;
  color: white;
  gap: 24px;
  padding: 32px 16px;

  .logo {
    margin-bottom: 16px;
    width: 120px;
  }

  .container-app-download-buttons {
    display: flex;
    width: 80%;
    gap: 16px;
    margin: 32px 0px;

    .app-download-button {
      width: 100%;
      cursor: pointer;
    }

    .app-download-button:hover {
      background-color: #1f2f3f;
    }
  }

  .container-copyright {
    margin: 16px 0px;
  }

  .container-legal-links {
    margin: 16px 0px;
    gap: 16px;
    a {
      text-decoration: none;
      color: inherit;
    }
    .legal-links {
      cursor: pointer;
    }
  }
}

.navigation-topbar {
  position: fixed;
  top: 0;
  margin: 0 auto;
  height: 52px;
  background-color: $color-background-dark;
  padding: 12px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgb(34, 34, 34);
  // overflow-y: hidden;
  z-index: 100;
  align-items: center;
  overflow: visible;
  transition: transform 0.3s ease-in-out;

  .logo {
    width: 80px;
  }

  .chakra-menu {
    z-index: 999;
  }

  .button-download {
    display: flex;
    flex-direction: row;
    color: white;
    gap: 12px;
    font-size: 14px;
    font-weight: bold;
  }
}

.navigation-topbar--hidden {
  transform: translateY(-100%);
}
