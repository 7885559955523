$black: #000000;
$color-primary: #57efa6;
$color-background-dark: #121920;

$font-size: 16px;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$spacing-small: 8px;
$spacing-medium: 16px;
$spacing-large: 24px;
$spacing-x-large: 32px;

$box-padding: 32px;

$sidebar-width: 240px;
