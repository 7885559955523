$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.App {
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

// Fix to mobile view. No desktop.
.fix-mobile-view {
  max-width: 480px;
  width: 100%;
  // Don't allow overflow
  overflow-x: hidden;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  padding: 32px 16px 16px;
  overflow-y: hidden;

  &.no-padding {
    padding: 0;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 48px 16px;
  }

  @media (min-width: $breakpoint-mobile) {
    padding: 36px;
  }

  // @media (min-width: $breakpoint-tablet) {
  //   padding-bottom: 64px;
  // }

  // @media (min-width: $breakpoint-desktop) {
  //   padding-bottom: 96px;
  // }
}

.section-content {
  @media (min-width: $breakpoint-mobile) {
    max-width: 100%;
    padding: 16px;
  }

  // @media (min-width: $breakpoint-tablet) {
  //   max-width: 692px;
  //   padding: 36px;
  // }

  // @media (min-width: $breakpoint-desktop) {
  //   max-width: 1024px;
  // }
}

.flex {
  display: flex;
}

.display-flex {
  display: flex;
}
.display-block {
  display: block;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-left {
  justify-content: flex-start;
}

.justify-right {
  justify-content: flex-end;
}

.justify-around {
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-responsive {
  display: flex;
  @media (min-width: $breakpoint-mobile) {
    flex-direction: column;
  }

  @media (min-width: $breakpoint-tablet) {
    max-width: 692px;
    padding: 36px;
  }

  @media (min-width: $breakpoint-desktop) {
    flex-direction: row;
  }
}

// Text Alignment

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.align-center {
  align-items: center;
}

.align-left {
  align-items: flex-start;
}

.align-right {
  align-items: right;
}

.align-bottom {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.max-width {
  width: 100%;
}

.max-height {
  height: 100%;
}

.half-width {
  width: 50%;
}

.half-height {
  height: 50%;
}

.margin-auto-all {
  margin: auto;
}

.margin-auto-hr {
  margin-left: auto;
  margin-right: auto;
}

.margin-auto-vr {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-top-navbar {
  margin-top: 48px;
}
